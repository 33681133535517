
class Modals {

    constructor() {

        this.modalsSelector = '.modal';
        this.modalContentSelector = '.modal-content';
        this.modalBGSelector = '.modal-bg';
        this.modalCloseSelector = '.modal-close';
        this.openModalButtonsSelector = '[data-modal]';

        this.modal = document.querySelector(this.modalsSelector);
        this.openModalButtons = document.querySelectorAll(this.openModalButtonsSelector);
        this.modalContent = document.querySelector(this.modalContentSelector);
        this.modalBG = document.querySelector(this.modalBGSelector);
        this.modalClose = document.querySelector(this.modalCloseSelector);

        if( this.modalBG ) {
            this.modalBG.addEventListener('click', (e) => {
                e.preventDefault();
                this.hide();
            });
        }

        if (this.modalClose) {
            this.modalClose.addEventListener('click', (e) => {
                e.preventDefault();
                this.hide();
            });
        }

        this.handleModalButtonClick = this.handleModalButtonClick.bind(this);
        
        this.openModalButtons.forEach((button, buttonIndex) => {
            
            console.log('asd');
            
            button.addEventListener('click', this.handleModalButtonClick);
            
        });

        document.querySelector('body').addEventListener('keyup', (e) => {
            if (e.keyCode === 27 ) {
                this.hide();
            }
        });
        
    }

    handleModalButtonClick(event) {
        event.preventDefault();
        
        console.log(this);
        
        const button = event.currentTarget;
        const type = button.dataset.type;
        

        if (!this.modalContent || typeof this.modalContent !== 'object') return false;

        if (type === 'youtube') {

            const youtubeCode = button.dataset.content;
            
            let iframe = '<iframe style="width:100%;height: 100%;" src="https://www.youtube.com/embed/' + youtubeCode + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

            this.modalContent.innerHTML = iframe;
        }

        this.show();

        return true;
    }

    show() {
        this.modal.classList.remove('hidden');
    }

    hide() {
        this.modal.classList.add('hidden');
        this.modalContent.textContent = '';
    }

}

export default Modals;