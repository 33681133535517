import Accordion from './accordion';
import Modals from './modals';

import './styles/normalize.css';
import './styles/main.less';

class Application {

    constructor() {
        
        new Promise((resolve, reject) => {
            this.accordion = new Accordion();
        });

        new Promise((resolve, reject) => {
            this.modals = new Modals();
        })
        .catch((error) => {
            alert('Ошибка при работе модалок:' + "\r\n" + error);
        });

        console.log('Application constructor - after create promise');

    }

    

}

new Application();