
class Accordion {

    constructor() {
        
        this.accordions = this.findAccordions();

        this.accordions.forEach(accordion => {
            accordion.items.forEach((item, indexItem) => {
                
                if( indexItem <= 0 ) {
                    item.classList.add( accordion.activeClassName );
                }

                item.querySelector('[data-title]').addEventListener('click', (e) => {

                    if( item.classList.contains( accordion.activeClassName ) !== true) {

                        accordion.accordion.querySelectorAll('[data-item]').forEach(accordionItem => {
                            accordionItem.classList.remove( accordion.activeClassName );
                        })

                        item.classList.add( accordion.activeClassName );

                    }

                });
            });
        });

    }

    findAccordions() {

        let accordions = [];

        document.querySelectorAll('[data-accordion]').forEach(accordionDOM => {
            let accordion = {
                name: accordionDOM.dataset.accordion,
                length: accordionDOM.querySelectorAll('[data-item]').length,
                items: accordionDOM.querySelectorAll('[data-item]'),
                activeClassName: accordionDOM.dataset.activeClass,
                accordion: accordionDOM
            };

            accordions.push(accordion);
        })

        return accordions;
    }
}

export default Accordion;